import { Button, ButtonType, Link } from '@hp/atomic';
import { useConfig } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CheckboxField, FormWrapper, PasswordField, TextField } from '@hp/form';
import { CenteredRow, Row } from '@hp/layout';
import { labels } from '@hp/locale';
import { Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React from 'react';

import { FormContentWrapper } from '../../styled';
import {
  LoginRegisterFormFields,
  RegisterLandingFormFields,
} from '../../types';
import { RegisterContent } from '../RegisterContent';
import { HaveAccountButton } from './styled';
import { useRegisterLandingForm } from './useRegisterLandingForm';
import { useRegisterLandingValidationSchema } from './useRegisterLandingValidationSchema';

const formId = 'registerLandingForm';

export const RegisterLandingForm = () => {
  const { initialValues, handleSubmit } = useRegisterLandingForm();
  const { config } = useConfig();

  const formik = useFormik<RegisterLandingFormFields>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: useRegisterLandingValidationSchema(),
  });

  const { language } = useLanguage();

  const externalLinks =
    config.externalLinks[language] ?? config.externalLinks.default;

  return (
    <>
      <FormContentWrapper>
        <FormWrapper formik={formik} flex>
          <RegisterContent />

          <TextField<LoginRegisterFormFields>
            formId={formId}
            name="email"
            label={labels.email}
            type="email"
            autocorrect="off"
            autocapitalize="none"
            testId="auth.registerLanding.input.email"
          />

          <PasswordField<LoginRegisterFormFields>
            formId={formId}
            name="password"
            label={labels.password}
            testId="auth.registerLanding.input.password"
          />

          <Row marginSize="m">
            <CheckboxField<LoginRegisterFormFields>
              formId={formId}
              name="termsAgreement"
              label={
                <Trans id="common.field.termsAgreement">
                  Souhlasím se{' '}
                  <Link
                    className="gaLink gaLink_terms"
                    href={externalLinks.termsConditions}
                    target="_blank"
                    testId="sender.link.terms"
                  >
                    všeobecnými obchodními podmínkami
                  </Link>{' '}
                  a{' '}
                  <Link
                    className="gaLink gaLink_privacy"
                    href={externalLinks.privacyPolicy}
                    target="_blank"
                    testId="sender.link.privacy"
                  >
                    zpracováním osobních údajů.
                  </Link>
                </Trans>
              }
              data-test="auth.checkbox.terms"
            />
          </Row>

          <HaveAccountButton
            className="gaButton gaButtonAction_haveAccount"
            type="button"
            buttonType={ButtonType.TEXT}
            to={'login'}
            data-test="auth.button.haveAccount"
          >
            <Trans id="auth.registerLanding.haveAccount">
              Už máte účet? Přihlašte se.
            </Trans>
          </HaveAccountButton>

          <CenteredRow marginSize="l">
            <Button
              bottom
              fullWidth
              type="submit"
              className="gaButton gaButtonSubmit_createAccount"
              buttonType={ButtonType.PRIMARY}
              fakeDisabled={!formik.isValid}
              data-test="auth.button.submit"
            >
              <Trans id="auth.register.button">Vytvořit účet</Trans>
            </Button>
          </CenteredRow>
        </FormWrapper>
      </FormContentWrapper>
    </>
  );
};
