import { Modal } from '@hp/modal';
import React, { FC } from 'react';

import { ForgottenPasswordFormProps } from '../../types';
import { ForgottenPasswordForm } from './ForgottenPasswordForm';

type ForgottenPasswordModalProps = ForgottenPasswordFormProps & {
  opened?: boolean;
  onCloseRequest?: () => void;
};

export const ForgottenPasswordModal: FC<ForgottenPasswordModalProps> = ({
  opened = false,
  onCloseRequest,
  email,
}) => {
  return (
    opened && (
      <Modal onCloseRequest={onCloseRequest} desktopMaxWidth={392}>
        <ForgottenPasswordForm email={email} />
      </Modal>
    )
  );
};
