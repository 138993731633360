import { useUserContext } from '@hp/context';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import { Trans } from '@lingui/react';
import React, { useState } from 'react';

import { ForgottenPasswordFormFields } from '../../types';

export const useForgottenPasswordForm = (email?: string) => {
  const [isSubmited, setIsSubmitted] = useState<boolean>(false);
  const { sendPasswordResetEmail } = useUserContext();
  const { showErrorInfo } = useSystemModal();

  const handleSubmit = async ({ email }: ForgottenPasswordFormFields) => {
    try {
      await sendPasswordResetEmail(email, 'resetPassword');
      setIsSubmitted(true);
    } catch (e) {
      switch (e.code) {
        case 'auth/user-not-found':
        case 'auth/user-disabled':
          showErrorInfo(
            <Trans id="auth.forgottenPassword.failed.unknow-user">
              Uživatel neexistuje nebo není aktivní.
            </Trans>,
          );
          break;
        default:
          showErrorInfo();
      }
    }
  };

  return {
    initialValues: { email: email || '' },
    handleSubmit,
    isSubmited,
  };
};
