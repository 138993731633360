import { PasswordField, TextField } from '@hp/form';
import { labels } from '@hp/locale';
import React, { FC } from 'react';

import { LoginFormFields } from '../../types';

type LoginContentProps = {
  onEmailBlur?: React.FocusEventHandler<HTMLInputElement>;
  formId: string;
};
export const LoginContent: FC<LoginContentProps> = ({
  onEmailBlur,
  formId,
}) => {
  return (
    <>
      <TextField<LoginFormFields>
        formId={formId}
        name="email"
        label={labels.email}
        type="email"
        autocorrect="off"
        autocapitalize="none"
        testId="auth.loginRegister.input.email"
        onBlur={onEmailBlur}
      />

      <PasswordField<LoginFormFields>
        formId={formId}
        name="password"
        label={labels.password}
        testId="auth.loginRegister.input.password"
      />
    </>
  );
};
