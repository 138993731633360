import { RouteNameType } from '@hp/seo';
import React, { FC } from 'react';

import { LoginRegisterType } from '../../types';
import { LoginRegisterForm } from './LoginRegisterForm';

type LoginRegisterProps = {
  type: LoginRegisterType;
  redirectTo?: RouteNameType;
};

export const LoginRegister: FC<LoginRegisterProps> = ({ type, redirectTo }) => {
  return <LoginRegisterForm type={type} redirectTo={redirectTo} />;
};
