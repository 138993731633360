import { useUserContext } from '@hp/context';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import { useRouter } from '@hp/seo';
import { Trans } from '@lingui/react';
import React, { useState } from 'react';

import { ResetPasswordFormFields, SetPasswordMode } from '../../types';
import { useChangeOrResetPasswordValidationSchema } from './useChangeOrResetPasswordValidationSchema';

export const useChangeOrResetPasswordForm = (
  mode: SetPasswordMode,
  code: string,
  email: string,
  onAbort: (errorCode: string) => void,
) => {
  const [isSubmited, setIsSubmitted] = useState<boolean>(false);
  const { showErrorInfo } = useSystemModal();
  const { replace, push } = useRouter();
  const { changePassword, setPassword, login } = useUserContext();
  const validationSchema = useChangeOrResetPasswordValidationSchema(mode);
  const [tmpPassword, setTmpPassword] = useState('');

  const handleSubmit = async ({
    password,
    oldPassword,
  }: ResetPasswordFormFields) => {
    if (isSubmited) {
      if (mode === SetPasswordMode.ResetPassword) {
        try {
          await login(email, tmpPassword);
          replace('accountHomepage');
        } catch (e) {
          await showErrorInfo();
          onAbort(e);
        }
      } else {
        push('accountProfile');
      }

      return;
    }
    setTmpPassword(password);
    try {
      await (mode === SetPasswordMode.ChangePassword
        ? changePassword(oldPassword, password)
        : setPassword(code, password));
      setIsSubmitted(true);
    } catch (e) {
      let message;
      switch (e.code) {
        case 'auth/weak-password':
          message = (
            <Trans id="auth.setPassword.weak-password">
              Zvolené heslo je příliš slabé
            </Trans>
          );
          await showErrorInfo(message);
          return;
        case 'auth/wrong-password':
          message = (
            <Trans id="auth.changePassword.wrong-password">
              Pro změnu hesla je třeba uvést správně stávající heslo.
            </Trans>
          );
          await showErrorInfo(message);
          return;
        default:
          onAbort(e.code);
          return;
      }
    }
  };

  const initialValues: ResetPasswordFormFields = {
    password: '',
    passwordConfirm: '',
    oldPassword: '',
  };
  return {
    handleSubmit,
    initialValues,
    isSubmited,
    validationSchema,
  };
};
