import { Link, Typography, TypographyType } from '@hp/atomic';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import { CenteredRow, Row } from '@hp/layout';
import { Trans } from '@lingui/react';
import React from 'react';

export const ReadMore: React.FC = ({}) => {
  const { showInfo } = useSystemModal();

  const showModal = () => {
    showInfo(
      <div>
        <CenteredRow marginSize="xs">
          <Trans id="auth.register.shortinfo.modal.title">
            Podmínky vytvoření účtu
          </Trans>
        </CenteredRow>
        <Row marginSize="l">
          <Typography type={TypographyType.BodySmallLight}>
            <Trans id="auth.register.shortinfo.modal.content">
              Vytvořením účtu nedochází k uzavření zasílatelské smlouvy, ta se
              uzavírá při předání zásilky DPD k přepravě.
              <br />
              <br />
              Klient odesláním formuláře potvrzuje správnost údajů v něm
              uvedených a zavazuje se, že se před odesláním jakékoliv zásilky
              prostřednictvím DPD účtu seznámí s VOP aktuálními k okamžiku
              předání zásilky k přepravě.
            </Trans>
          </Typography>
        </Row>
      </div>,
      false,
    );
  };

  return (
    <Trans id="auth.register.shortinfo.title">
      Přečtěte si <Link onClick={showModal}>podmínky vytvoření účtu</Link>.
    </Trans>
  );
};
