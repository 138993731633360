import {
  Button,
  ButtonType,
  CheckCircleIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { FormWrapper, TextField } from '@hp/form';
import { CenteredRow, Inner, Row } from '@hp/layout';
import { labels } from '@hp/locale';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/react';
import { useFormik } from 'formik';
import React, { FC } from 'react';

import {
  ForgottenPasswordFormFields,
  ForgottenPasswordFormProps,
} from '../../types';
import { useForgottenPasswordForm } from './useForgottenPasswordForm';
import { useForgottenPasswordValidationSchema } from './useForgottenPasswordValidationSchema';

const formId = 'forgottenPasswordForm';

export const ForgottenPasswordForm: FC<ForgottenPasswordFormProps> = ({
  noTitle,
  children,
  email,
}) => {
  const { initialValues, handleSubmit, isSubmited } = useForgottenPasswordForm(
    email,
  );

  const formik = useFormik<ForgottenPasswordFormFields>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: useForgottenPasswordValidationSchema,
  });

  return (
    <>
      {!isSubmited && !noTitle && (
        <CenteredRow>
          <Typography type={TypographyType.BodyMicro} color={colors.black}>
            <Trans id="auth.forgottenPassword.title">
              Zapomněli jste heslo?
            </Trans>
          </Typography>
        </CenteredRow>
      )}

      <FormWrapper formik={formik}>
        <Inner paddingY="xs" paddingX="m">
          {!isSubmited ? (
            <>
              {children}
              <Row marginSize="m">
                <TextField<ForgottenPasswordFormFields>
                  formId={formId}
                  name="email"
                  label={labels.email}
                  type="email"
                  autocorrect="off"
                  autocapitalize="none"
                  testId="auth.forgottenPassword.input.email"
                />
              </Row>

              <CenteredRow>
                <Button
                  fullWidth
                  type="submit"
                  className="gaButton gaButtonSubmit_forgottenPassword"
                  buttonType={ButtonType.PRIMARY}
                  fakeDisabled={!formik.isValid}
                  data-test="auth.forgottenPassword.button.submit"
                >
                  <Trans id="auth.forgottenPassword.submit">
                    Obnovit heslo
                  </Trans>
                </Button>
              </CenteredRow>
            </>
          ) : (
            <>
              <CenteredRow>
                <CheckCircleIcon />
              </CenteredRow>
              <CenteredRow marginSize="m">
                <Typography
                  type={TypographyType.BodyRegular}
                  color={colors.green}
                  textAlign="center"
                >
                  <Trans id="auth.forgottenPassword.linkSent">
                    Na uvedený e-mail jsme poslali odkaz, přes který si
                    nastavíte nové heslo.
                  </Trans>
                </Typography>
              </CenteredRow>
            </>
          )}
        </Inner>
      </FormWrapper>
    </>
  );
};
