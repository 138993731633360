import { breakpoints, colors, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

export const ModalOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0;
  background-color: ${colors.black_opacity_05};
  z-index: 50;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: ${breakpoints.minDesktop}) {
    padding: 25px;
  }
`;

export const ModalContainer = styled.div<{ desktopMaxWidth: number }>`
  width: 100%;
  margin: ${spacing.m};
  background-color: ${colors.white};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 6px;

  @media (min-width: ${breakpoints.minDesktop}) {
    height: auto;
    max-height: 80vh;
    max-width: ${({ desktopMaxWidth }) => desktopMaxWidth}px;
  }
`;

export const ModalContent = styled.div`
  color: ${colors.black};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
  padding: 10px 0 0;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.gray_warm};
    border-radius: 10px;
  }

  @media (min-width: ${breakpoints.minDesktop}) {
    max-height: 70vh;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${colors.transparent};
  width: 17px;
  height: 17px;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: 0;
  z-index: 10;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: ${colors.black};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Progress = styled.div`
  border-top: 2px solid ${colors.gray_mid};
  height: 2px;
`;

export const ProgressWrapper = styled.div`
  margin-left: 6px;
  margin-right: 6px;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
`;
