import { useYup } from '@hp/form';

import { usePasswordValidations } from '../../hooks/usePasswordValidations';
import { ResetPasswordFormFields, SetPasswordMode } from '../../types';

export const useChangeOrResetPasswordValidationSchema = (
  mode: SetPasswordMode,
) => {
  const Yup = useYup();

  const { password, passwordConfirm } = usePasswordValidations(Yup);

  return Yup.object().shape<ResetPasswordFormFields>({
    password,
    passwordConfirm,
    oldPassword:
      mode === SetPasswordMode.ChangePassword
        ? password
        : Yup.string().optional().nullable(),
  });
};
