import { regexPassword } from '@hp/config';
import { useYup } from '@hp/form';
import { inlineKeys, useI18n } from '@hp/locale';

export const usePasswordValidations = (yup: ReturnType<typeof useYup>) => {
  const i18n = useI18n();
  return {
    password: yup
      .string()
      .required()
      .max(50)
      .matches(regexPassword, i18n._(inlineKeys.password.key)),
    passwordConfirm: yup
      .string()
      .required()
      .max(50)
      .matches(regexPassword, i18n._(inlineKeys.password.key))
      .oneOf(
        [yup.ref('password'), null],
        i18n._(inlineKeys.passwordConfirm.key),
      ),
  };
};
