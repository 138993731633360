import { useYup } from '@hp/form';

import { ForgottenPasswordFormFields } from '../../types';

export const useForgottenPasswordValidationSchema = () => {
  const Yup = useYup();

  return Yup.object().shape<ForgottenPasswordFormFields>({
    email: Yup.string().email().max(50).required(),
  });
};
