import { Button, H2, NextLink } from '@hp/atomic';
import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

export const LogoLink = styled(NextLink)`
  display: flex;
  width: fit-content;
  margin: 20px auto 12px;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin: 32px auto;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: 20px auto 12px;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin: 32px auto;
  }
`;

export const Logo = styled.div`
  color: ${colors.green};
  text-align: center;
  width: fit-content;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto 10px;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin: 0 auto 32px;
  }
`;

export const Title = styled(H2)`
  margin: auto;
  max-width: 183px;
`;

export const FormContentWrapper = styled.div`
  max-width: ${elements.formMaxWidth};
  margin: auto;

  @media (min-width: ${breakpoints.minDesktop}) {
    width: ${elements.formMaxWidth};
  }
`;

export const ForgottenPasswordButton = styled(Button)`
  text-transform: none;
  text-decoration: underline;
  font-weight: ${typography.fontWeight.light};
  margin: ${spacing.m} 0;
`;
