import { Typography, TypographyType } from '@hp/atomic';
import { spacing } from '@hp/theme';
import React from 'react';
import styled from 'styled-components';

type ItemProps = {
  className?: string;
  icon?: JSX.Element;
  noMargin?: boolean;
};

const RowStyled = styled.div<Pick<ItemProps, 'noMargin'>>`
  display: flex;
  align-items: center;
  margin: ${({ noMargin }) => (noMargin ? '0' : `${spacing.m} 0`)};
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-right: ${spacing.m};
  margin-bottom: 2px;
`;

const TypographyStyled = styled(Typography)`
  margin-top: 0;
`;

export const Item: React.FC<ItemProps> = ({
  icon,
  noMargin = false,
  children,
  ...props
}) => (
  <RowStyled noMargin={noMargin} {...props}>
    <IconWrapper>{icon}</IconWrapper>
    <TypographyStyled type={TypographyType.BodySmallLight}>
      {children}
    </TypographyStyled>
  </RowStyled>
);
