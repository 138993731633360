import { ButtonType } from '@hp/atomic';
import { useUserContext } from '@hp/context';
import { useSystemModal } from '@hp/core/src/providers/SystemModal';
import { RouteNameType, useFlow, useRouter } from '@hp/seo';
import { Trans } from '@lingui/react';
import React, { useEffect, useState } from 'react';

import { ForgottenPasswordButton } from '../../styled';
import { LoginRegisterFormFields, LoginRegisterType } from '../../types';
import { ForgottenPasswordModal } from '../ForgottenPasswordModal';
import { useLoginRegisterValidationSchema } from './useLoginRegisterValidationSchema';

type Options = {
  type: LoginRegisterType;
  redirectTo?: RouteNameType;
  initEmail?: string;
};

export const useLoginRegisterForm = ({
  type,
  redirectTo,
  initEmail,
}: Options) => {
  const { createUser, login, userExists } = useUserContext();
  const [forgottenPasswordVisible, setForgottenPasswordVisible] = useState(
    false,
  );
  const { showErrorInfo } = useSystemModal();
  const [emailIsKnown, setEmailIsKnown] = useState(false);
  const [email, setEmail] = useState<string>(initEmail ?? '');

  const forgottenPasswordModal = (
    <ForgottenPasswordModal
      onCloseRequest={() => setForgottenPasswordVisible(false)}
      opened={forgottenPasswordVisible}
      email={email}
    />
  );

  const forgottenPasswordButton = (
    <ForgottenPasswordButton
      className="gaButton gaButtonAction_forgottenPassword"
      type="button"
      buttonType={ButtonType.TEXT}
      onClick={() => setForgottenPasswordVisible(!forgottenPasswordVisible)}
      data-test="auth.button.forgottenPassword"
    >
      <Trans id="auth.forgottenPassword.button">Zapomněli jste heslo?</Trans>
    </ForgottenPasswordButton>
  );

  const checkAccountByEmail = (email: string) => {
    return userExists(email).then((known) => {
      setEmailIsKnown(known);
      setEmail(email);
    });
  };

  useEffect(() => {
    if (initEmail) {
      checkAccountByEmail(initEmail);
    }
  }, [initEmail]);

  const isSubmitValid = (type: LoginRegisterType) => {
    const res =
      (emailIsKnown && type === LoginRegisterType.LOGIN) ||
      (!emailIsKnown && type === LoginRegisterType.REGISTER);
    return res;
  };

  const { push } = useRouter();
  const { nextPage } = useFlow();

  const handleSubmit = async ({
    email,
    password,
    type,
  }: LoginRegisterFormFields) => {
    const loginOrRegister =
      type === LoginRegisterType.LOGIN ? login : createUser;

    try {
      await loginOrRegister(email, password);
      if (redirectTo) push(redirectTo);
      else nextPage();
    } catch (e) {
      switch (e.code) {
        case 'auth/wrong-password':
          showErrorInfo(
            <Trans id="auth.login.wrong-password">
              Bohužel, heslo není správně.
            </Trans>,
          );
          break;
        case 'auth/weak-password':
          showErrorInfo(
            <Trans id="auth.setPassword.weak-password">
              Zvolené heslo je příliš slabé
            </Trans>,
          );
          break;
        default:
          showErrorInfo();
      }
    }
  };
  const validationSchema = useLoginRegisterValidationSchema(emailIsKnown);

  const initialValues: LoginRegisterFormFields = {
    type,
    email,
    password: '',
    termsAgreement: false,
    newsletterAgreement: true,
  };

  return {
    handleSubmit,
    initialValues,
    userExists,
    checkAccountByEmail,
    emailIsKnown,
    email,
    validationSchema,
    forgottenPasswordModal,
    forgottenPasswordButton,
    isSubmitValid,
  };
};
