import { Loading, Note } from '@hp/atomic';
import { useUserContext } from '@hp/context';
import { Trans } from '@lingui/react';
import React, { FC, useEffect, useState } from 'react';

import { SetPasswordMode } from '../../types';
import { ForgottenPasswordForm } from '../ForgottenPasswordModal/ForgottenPasswordForm';
import { ChangeOrResetPasswordForm } from './ChangeOrResetPasswordForm';
import { Logo, Wrapper } from './styled';

type Props = {
  code: string;
};

export const ResetPassword: FC<Props> = ({ code }) => {
  const { verifyPasswordResetCode } = useUserContext();
  const [email, setEmail] = useState<string>();
  const [forgottenPasswordVisible, setForgottenPasswordVisible] = useState(
    false,
  );
  const [message, setMessage] = useState<JSX.Element>();
  const showForgotettenPwdForm = !code || forgottenPasswordVisible;
  const loading = !email && !showForgotettenPwdForm;

  useEffect(() => {
    if (code)
      verifyPasswordResetCode(code)
        .then((email) => {
          if (email) setEmail(email);
        })
        .catch((e) => handleError(e.code));
  }, [code]);

  const handleError = (errorCode: string) => {
    switch (errorCode) {
      case 'auth/expired-action-code': {
        setMessage(
          <Trans id="auth.forgottenPassword.failed.expired-action-code">
            Bohužel, časový limit pro nastavení nového hesla vypršel. Zadejte
            prosím znovu váš email, a akci opakujte.{' '}
          </Trans>,
        );
        break;
      }
      case 'auth/invalid-action-code': {
        setMessage(
          <Trans id="auth.forgottenPassword.failed.invalid-action-code">
            Bohužel, váš odkaz již byl použit. Zadejte prosím znovu váš email a
            zkuste akci opakovat.
          </Trans>,
        );
        break;
      }
      case 'auth/user-not-found':
      case 'auth/user-disabled':
        setMessage(
          <Trans id="auth.setPassword.user-disabled">
            Heslo nelze změnit, uživatel není aktivní nebo byl smazán.
          </Trans>,
        );
        break;
      default:
        setMessage(
          <Trans id="auth.setPassword.unknownError">
            Heslo nyní nelze změnit, zkuste proces opakovat, případně
            kontaktovat DPD.
          </Trans>,
        );
        break;
    }
    setForgottenPasswordVisible(true);
  };

  return loading ? (
    <Loading />
  ) : (
    <Wrapper>
      <Logo />
      {showForgotettenPwdForm ? (
        <ForgottenPasswordForm noTitle={!!code} email={email}>
          {message && <Note>{message}</Note>}
        </ForgottenPasswordForm>
      ) : (
        <ChangeOrResetPasswordForm
          mode={SetPasswordMode.ResetPassword}
          code={code}
          email={email}
          onAbort={handleError}
        />
      )}
    </Wrapper>
  );
};

export { ChangeOrResetPasswordForm };
