import { WideWrapperMobile } from '@hp/layout';
import React, { FC } from 'react';

import { RegisterLandingForm } from './RegisterLandingForm';
import { Image } from './styled';

type RegisterLandingProps = {
  campaign?: string;
};

export const RegisterLanding: FC<RegisterLandingProps> = ({ campaign }) => {
  const source = (campaign: string) => {
    switch (campaign) {
      case 'b':
        return '/images/register_campaign_b.png';
      case 'c':
        return '/images/register_campaign_c.png';
      case 'a':
      default:
        return '/images/register_campaign_a.png';
    }
  };

  return (
    <>
      <WideWrapperMobile fullWidth>
        <Image src={source(campaign)} />
      </WideWrapperMobile>

      <RegisterLandingForm />
    </>
  );
};
