import { useYup } from '@hp/form';
import { inlineKeys, useI18n } from '@hp/locale';

import { usePasswordValidations } from '../../hooks/usePasswordValidations';
import { LoginRegisterFormFields, LoginRegisterType } from '../../types';

export const useLoginRegisterValidationSchema = (emailIsKnown: boolean) => {
  const Yup = useYup();
  const i18n = useI18n();
  const { password } = usePasswordValidations(Yup);

  return Yup.object().shape<LoginRegisterFormFields>({
    type: Yup.string(),
    email: Yup.string().when('type', {
      is: (value) => value === LoginRegisterType.REGISTER,
      then: emailIsKnown
        ? Yup.string()
            .email()
            .oneOf([], i18n._(inlineKeys.registrationNotValidForThisEmail.key))
            .required()
        : Yup.string().email().max(100).required(),
      otherwise: !emailIsKnown
        ? Yup.string()
            .email()
            .oneOf([], i18n._(inlineKeys.loginNotValidForThisEmail.key))
            .required()
        : Yup.string().email().max(100).required(),
    }),
    password,
    termsAgreement: Yup.boolean().when('type', {
      is: (value) => value === LoginRegisterType.REGISTER,
      then: Yup.boolean().oneOf([true], i18n._(inlineKeys.terms.key)),
      otherwise: Yup.boolean(),
    }),
    newsletterAgreement: Yup.boolean(),
  });
};
