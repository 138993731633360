import { LinkButton } from '@hp/atomic';
import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: auto;
`;
export const HaveAccountButton = styled(LinkButton)`
  text-transform: none;
  text-decoration: underline;
`;
