import {
  Button,
  ButtonType,
  CheckCircleIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { FormWrapper, PasswordField } from '@hp/form';
import { CenteredRow, CenteredWrapper } from '@hp/layout';
import { labels } from '@hp/locale';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React, { FC } from 'react';

import { FormContentWrapper } from '../../styled';
import { ResetPasswordFormFields, SetPasswordMode } from '../../types';
import { useChangeOrResetPasswordForm } from './useChangeOrResetPasswordForm';

const formId = 'resetPasswordForm';

type Props = (
  | {
      mode: SetPasswordMode.ResetPassword;
      /** required when SetPasswordMode.ResetPassword */
      code: string;
      email?: string;
    }
  | {
      mode: SetPasswordMode.ChangePassword;
      /** is empty for this mode */
      code?: null;
      email?: null;
    }
) & {
  /** onAbort occurs on any error which cannot be handled in a form */
  onAbort: (errorCode: string) => void;
};

export const ChangeOrResetPasswordForm: FC<Props> = ({
  mode,
  code,
  email,
  onAbort,
}) => {
  const {
    initialValues,
    handleSubmit,
    isSubmited,
    validationSchema,
  } = useChangeOrResetPasswordForm(mode, code, email, onAbort);

  const formik = useFormik<ResetPasswordFormFields>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  return (
    <FormContentWrapper>
      <FormWrapper formik={formik} flex>
        {isSubmited ? (
          <CenteredWrapper>
            <CenteredRow>
              <CheckCircleIcon />
            </CenteredRow>
            <CenteredRow marginSize="m">
              <Typography
                type={TypographyType.BodyRegular}
                color={colors.green}
                textAlign="center"
              >
                {mode === SetPasswordMode.ResetPassword ? (
                  <Trans id="auth.resetPassword.success">
                    Nové heslo bylo uloženo.
                  </Trans>
                ) : (
                  <Trans id="auth.changePassword.success">
                    Heslo bylo změněno.
                  </Trans>
                )}
              </Typography>
            </CenteredRow>
            <CenteredRow marginSize="l">
              <Button
                bottom
                fullWidth
                type="submit"
                className="gaButton gaButtonSubmit_loginLink"
                buttonType={ButtonType.PRIMARY}
                fakeDisabled={!formik.isValid}
                data-test="auth.button.submit"
              >
                {mode === SetPasswordMode.ResetPassword ? (
                  <Trans id="auth.login.button">Přihlásit se</Trans>
                ) : (
                  <Trans id="common.button.ok">OK</Trans>
                )}
              </Button>
            </CenteredRow>
          </CenteredWrapper>
        ) : (
          <>
            {mode === SetPasswordMode.ChangePassword && (
              <PasswordField<ResetPasswordFormFields>
                formId={formId}
                name="oldPassword"
                label={labels.passwordOld}
                testId="auth.input.oldPassword"
              />
            )}
            <p>
              <Typography type={TypographyType.Heading2Light}>
                <Trans id="auth.resetPassword.title">
                  Zvolte si nové heslo
                </Trans>
              </Typography>
            </p>

            <PasswordField<ResetPasswordFormFields>
              formId={formId}
              name="password"
              label={labels.passwordNew}
              testId="auth.input.passwordNew"
            />

            <PasswordField<ResetPasswordFormFields>
              formId={formId}
              name="passwordConfirm"
              label={labels.passwordNewConfirm}
              testId="auth.input.passwordConfirm"
            />

            <CenteredRow marginSize="l">
              <Button
                bottom
                fullWidth
                type="submit"
                className="gaButton gaButtonSubmit_resetPassword"
                buttonType={ButtonType.PRIMARY}
                fakeDisabled={!formik.isValid}
                data-test="auth.button.submit"
              >
                <Trans id="auth.resetPassword.button">Změnit heslo</Trans>
              </Button>
            </CenteredRow>
          </>
        )}
      </FormWrapper>
    </FormContentWrapper>
  );
};
