import { DPDLogo } from '@hp/atomic';
import { spacing } from '@hp/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Logo = styled(DPDLogo)`
  height: 32px;
  width: auto;
  margin: ${spacing.l} 0;
`;
