import { useUserContext } from '@hp/context';
import { useRouter } from '@hp/seo';

import { RegisterLandingFormFields } from '../../types';

export const useRegisterLandingForm = () => {
  const { createUser } = useUserContext();
  const { push } = useRouter();
  const handleSubmit = async ({
    email,
    password,
  }: RegisterLandingFormFields) => {
    try {
      await createUser(email, password);
      push('accountHomepage');
    } catch (e) {
      console.log(e);
      //todo:
      alert('ERROR ' + e.code);
      throw e;
    }
  };

  const initialValues: RegisterLandingFormFields = {
    email: '',
    password: '',
    termsAgreement: false,
    newsletterAgreement: true,
  };

  return {
    handleSubmit,
    initialValues,
  };
};
