export enum LoginRegisterType {
  LOGIN = 'login',
  REGISTER = 'register',
}

export type LoginFormFields = {
  email: string;
  password: string;
};

export type LoginRegisterFormFields = {
  type: LoginRegisterType;
  termsAgreement: boolean;
  newsletterAgreement: boolean;
} & LoginFormFields;

export type RegisterLandingFormFields = {
  email: string;
  password: string;
  termsAgreement: boolean;
  newsletterAgreement: boolean;
};

export type ForgottenPasswordFormFields = {
  email: string;
};

export type ForgottenPasswordFormProps = {
  noTitle?: boolean;
  email?: string;
};

export type ForgottenPasswordModalProps = ForgottenPasswordFormProps & {
  opened?: boolean;
  onCloseRequest?: () => void;
};

export type ResetPasswordFormFields = {
  oldPassword?: string;
  password: string;
  passwordConfirm: string;
};

export enum SetPasswordMode {
  ResetPassword = 'reset-password',
  ChangePassword = 'change-password',
}
