import { useYup } from '@hp/form';
import { inlineKeys, useI18n } from '@hp/locale';

import { usePasswordValidations } from '../../hooks/usePasswordValidations';
import { LoginRegisterType, RegisterLandingFormFields } from '../../types';

export const useRegisterLandingValidationSchema = () => {
  const Yup = useYup();
  const i18n = useI18n();
  const { password } = usePasswordValidations(Yup);

  return Yup.object().shape<RegisterLandingFormFields>({
    email: Yup.string().email().max(100).required(),
    password,
    termsAgreement: Yup.boolean().when('type', {
      is: (value) => value === LoginRegisterType.REGISTER,
      then: Yup.boolean().oneOf([true], i18n._(inlineKeys.terms.key)),
      otherwise: Yup.boolean(),
    }),
    newsletterAgreement: Yup.boolean(),
  });
};
