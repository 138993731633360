import {
  Button,
  ButtonType,
  Link,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { useConfig } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { CheckboxField, FormWrapper, SwitchButtonField } from '@hp/form';
import { CenteredRow, Row } from '@hp/layout';
import { SenderFormFields } from '@hp/order/src/types';
import { RouteNameType } from '@hp/seo';
import { useObjectLocalStorage } from '@hp/utils';
import { Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { FormContentWrapper } from '../../styled';
import { LoginRegisterFormFields, LoginRegisterType } from '../../types';
import { ReadMore } from '../ReadMore';
import { RegisterContent } from '../RegisterContent';
import { LoginContent } from './LoginContent';
import { useLoginRegisterForm } from './useLoginRegisterForm';

export const formId = 'loginRegisterForm';

type LoginRegisterFormProps = {
  type: LoginRegisterType;
  redirectTo?: RouteNameType;
};

export const LoginRegisterForm: FC<LoginRegisterFormProps> = ({
  type,
  redirectTo,
}) => {
  const { config } = useConfig();
  const [sender] = useObjectLocalStorage<SenderFormFields>('sender');
  const {
    initialValues,
    handleSubmit,
    checkAccountByEmail,
    validationSchema,
    forgottenPasswordModal,
    forgottenPasswordButton,
    isSubmitValid,
  } = useLoginRegisterForm({ type, redirectTo, initEmail: sender?.email });

  const formik = useFormik<LoginRegisterFormFields>({
    initialValues,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (formik.values.email) {
      formik.setTouched({ email: true }, false);
      checkAccountByEmail(formik.values.email).then(() =>
        formik.validateForm(),
      );
    }
  }, [initialValues.email]);

  const { language } = useLanguage();

  const externalLinks =
    config.externalLinks[language] ?? config.externalLinks.default;

  const onEmailBlur = () => {
    // if error is unregistered email, clear email error and validate with checkAccountByEmail on blur
    // TODO: better comparison than raw string, somehow compare localized, with inline keys or trans
    if (
      formik.values.email !== '' &&
      (formik.errors.email ===
        'Pro zadaný email není registrován žádný účet.' ||
        formik.errors.email ===
          'There is no registered account with this email.')
    ) {
      formik.setErrors({ email: '' });
    }

    checkAccountByEmail(formik.values.email).then(() => formik.validateForm());
  };

  return (
    <>
      <FormContentWrapper>
        <FormWrapper formik={formik} flex marginBottomSize="xl">
          <SwitchButtonField<LoginRegisterType>
            formId={formId}
            name="type"
            extraMarginBottom
          >
            {[
              {
                value: LoginRegisterType.LOGIN,
                content: () => (
                  <Typography
                    type={TypographyType.BodySmall}
                    textAlign="center"
                  >
                    <Trans id="auth.loginRegisterType.login">
                      Přihlásit se
                    </Trans>
                  </Typography>
                ),
                testId: 'auth.loginRegisterType.login',
              },
              {
                value: LoginRegisterType.REGISTER,
                content: () => (
                  <Typography
                    type={TypographyType.BodySmall}
                    textAlign="center"
                  >
                    <Trans id="common.loginRegisterType.register">
                      Vytvořit účet
                    </Trans>
                  </Typography>
                ),
                testId: 'auth.loginRegisterType.register',
              },
            ]}
          </SwitchButtonField>

          {formik.values.type === LoginRegisterType.REGISTER && (
            <RegisterContent />
          )}

          <LoginContent formId={formId} onEmailBlur={onEmailBlur} />

          {formik.values.type === LoginRegisterType.LOGIN && (
            <>{forgottenPasswordButton}</>
          )}

          {formik.values.type === LoginRegisterType.REGISTER && (
            <>
              <Row marginSize="m">
                <CheckboxField<LoginRegisterFormFields>
                  formId={formId}
                  name="termsAgreement"
                  label={
                    <Trans id="common.field.termsAgreement">
                      Souhlasím se{' '}
                      <Link
                        className="gaLink gaLink_terms"
                        href={externalLinks.termsConditions}
                        target="_blank"
                        testId="sender.link.terms"
                      >
                        všeobecnými obchodními podmínkami
                      </Link>{' '}
                      a{' '}
                      <Link
                        className="gaLink gaLink_privacy"
                        href={externalLinks.privacyPolicy}
                        target="_blank"
                        testId="sender.link.privacy"
                      >
                        zpracováním osobních údajů.
                      </Link>
                    </Trans>
                  }
                  data-test="auth.checkbox.terms"
                />
              </Row>

              <Row marginTop="zero" marginBottom="m">
                <Typography type={TypographyType.BodyMicroLight}>
                  <ReadMore />
                </Typography>
              </Row>
            </>
          )}

          <CenteredRow>
            <Button
              bottom
              fullWidth
              type="submit"
              className="gaButton gaButtonSubmit_login"
              buttonType={ButtonType.PRIMARY}
              fakeDisabled={
                formik.values.type === LoginRegisterType.LOGIN
                  ? /* because we are not able to check (and get programmatically) autocompleted value - for login we check only valid email */
                    !!formik.errors.email ||
                    (!isSubmitValid(formik.values.type) &&
                      Object.keys(formik.touched).length > 0)
                  : !formik.isValid || !isSubmitValid(formik.values.type)
              }
              data-test="auth.button.submit"
            >
              {formik.values.type === LoginRegisterType.LOGIN ? (
                <Trans id="auth.login.button">Přihlásit se</Trans>
              ) : (
                <Trans id="auth.register.button">Vytvořit účet</Trans>
              )}
            </Button>
          </CenteredRow>
        </FormWrapper>
      </FormContentWrapper>

      {forgottenPasswordModal}
    </>
  );
};
