import {
  H2,
  MapMarkerDistanceIcon,
  ShieldIcon,
  SquaresColorIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React from 'react';
import styled from 'styled-components';

import { Item } from './Item';

const H2Styled = styled(H2)`
  margin-top: 0;
`;

export const RegisterContent = () => {
  return (
    // div instead of fragment to cancel flex because of different margin behavior
    <div>
      <H2Styled>
        <Trans id="auth.register.landing.title">
          <Typography
            type={TypographyType.Heading2Light}
            color={colors.red_main}
          >
            Využijte rychlejší odesílání
          </Typography>
          <br />
          <Typography type={TypographyType.Heading2Light}>
            bez zbytečného vyplňování
          </Typography>
        </Trans>
      </H2Styled>
      <p>
        <Typography type={TypographyType.BodyLight}>
          <Trans id="auth.register.landing.subtitle">
            Vytvořte si účet s těmito výhodami:
          </Trans>
        </Typography>
      </p>
      <Item icon={<MapMarkerDistanceIcon />}>
        <Trans id="auth.register.landing.tracking">
          Sledování a archiv zásilek na jednom místě
        </Trans>
      </Item>
      <Item icon={<SquaresColorIcon />}>
        <Trans id="auth.register.landing.sending">
          Předvyplněné adresy, ze kterých posíláte
        </Trans>
      </Item>
      <Item icon={<ShieldIcon width="30" />}>
        <Trans id="auth.register.landing.account">Uložený dobírkový účet</Trans>
      </Item>
    </div>
  );
};
